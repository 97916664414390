import { AEngine } from "../AEngine.js";
import { ALL_MAP_OPTIONS, MAP_OPTIONS } from "./AMapStructs.js";
import { asyncMapArray } from "../../utils/tools.js";
import { EVENTS } from "../../services/AEventService.js";
import { AGeoUtils } from "./AGeoUtils.js";
export class AGeoService {
    get geoObjects() { return this.cached.Geo; }
    constructor() {
        this.cached = undefined;
    }
    autoInit() {
        Events.h_once(EVENTS.API_READY, async () => {
            this.defaultCoordinates = await this.fetchDefaultCoordinates();
            globalThis.DefaultBounds = () => this.defaultCoordinates.bounds;
        });
        Events.hardwire('GeoResponse', (res) => {
            if (res.Success === false) {
                AEngine.warn(`Invalid GeoResponse`, res);
                return;
            }
            if (this.cached === undefined) {
                this.cached = res;
            }
            else {
                const keys = Object.keys(res.Geo);
                if (keys.length === 0) {
                    throw new Error(`Invalid GeoResponse []`);
                }
                keys.map(key => {
                    if (this.cached.Geo[key] === undefined) {
                        this.cached.Geo[key] = res.Geo[key];
                    }
                });
            }
            Events.tryInvoke('GeoResponseStored');
        });
    }
    isGeoDataCached(geoType) {
        return (this.cached?.Geo !== undefined && this.cached.Geo[geoType]);
    }
    async waitForGeoResults(mapOptions) {
        await Promise.all(mapOptions.map((mapOption) => {
            return this.waitForGeoResultSingle(mapOption);
        }));
    }
    async waitForGeoResultSingle(mapOption) {
        const key = this.toKey(mapOption);
        while (!this.cached || this.cached && this.cached.Geo[key] === undefined) {
            await Loading.waitForEvent('GeoResponseStored', true);
        }
        AEngine.log(`Received GeoData [%c${key}%n]`);
    }
    async fetch(opt) {
        const requestData = {};
        const mapOptions = this.mapOptionBitmaskToArray(opt);
        const promise = this.waitForGeoResults(mapOptions);
        for (const mO of mapOptions) {
            requestData[this.toKey(mO)] = null;
        }
        AEngine.log(`Fetching Geo [%c${mapOptions.map(v => MAP_OPTIONS[v]).join(', ')}%n]`);
        requestService.send('GeoRequest', requestData);
        await promise;
        return this.cached;
    }
    async fetchBoundingBox() {
        const promise = Loading.waitForEvent('GeoResponse', true);
        requestService.send('GeoRequest', {});
        const result = await promise;
        const bb = result.BoundingBox;
        let bounds = new google.maps.LatLngBounds();
        if (bounds.isEmpty()) {
            bounds.extend({ lng: bb[0][0], lat: bb[0][1] });
            bounds.extend({ lng: bb[1][0], lat: bb[1][1] });
        }
        return bounds;
    }
    async fetchDefaultCoordinates() {
        const bb = await this.fetchBoundingBox();
        return {
            bounds: bb,
            center: bb.getCenter()
        };
    }
    toKey(mapOption) {
        const str = (typeof mapOption === 'number') ? MAP_OPTIONS[mapOption] : mapOption;
        return (str.endsWith('s')) ? str.substring(0, str.length - 1) : str;
    }
    mapOptionBitmaskToArray(mapOption) {
        return ALL_MAP_OPTIONS.filter(opt => mapOption & opt).map((opt) => {
            return opt;
        });
    }
    async load(map, mapOption, opts) {
        const response = await AEngine.get(AGeoService).fetch(mapOption);
        const geoType = coreMapService.mapOptionToGeoType(mapOption);
        const { Box, GeoMap, Type } = response.Geo[geoType];
        const geoObjects = Object.values(GeoMap);
        var st = performance.now();
        const geoInstances = await asyncMapArray(geoObjects, 100, (geoObject) => {
            const { Active, GeoId, Name, Attributes } = geoObject;
            const res = AGeoUtils.parseAny(map, geoObject.Geo, {
                Active, GeoId, Name, Attributes, GeoLayer: mapOption
            }, opts);
            if (opts.skipPaint !== true) {
                for (let geoInstance of Array.isArray(res) ? res : [res]) {
                    if (geoPaintService.allowPaint(geoInstance)) {
                        geoPaintService.paint(geoInstance);
                    }
                }
            }
            return res;
        }).then((geoInstances) => geoInstances.flat());
        // if (opts.skipPaint !== true) {
        //   geoPaintService.paintAll(geoInstances)
        // }
        AEngine.log(`Done loading GeoType [%c${geoType}%r]`, Math.floor(performance.now() - st));
        if (opts.addClickListener !== undefined && opts.addClickListener !== false) {
            // TODO: Implement filtering for other types
            const clickEvent = (typeof opts.addClickListener === 'function') ? opts.addClickListener : undefined;
            coreMapService.addClickListeners(geoInstances, clickEvent);
        }
        map._geoObjectsVisible[geoType] = opts.parseToMap;
        map._geoInstances[geoType] = geoInstances;
        return geoInstances;
    }
    async setVisibility(geoInstances, opts) {
        if (opts.parseToMap === true) {
            if (opts.ignoreOutsideBounds === true) {
                // Only display geoInstances that are within the map view / bounds
                const bounds = PageScript.map.getBounds();
                await asyncMapArray(geoInstances, 100, (geoInstance) => {
                    if (bounds.contains(AGeoUtils.calcCenter(geoInstance))) {
                        geoInstance.setMap(PageScript.map);
                    }
                    else {
                        geoInstance.setMap(null);
                    }
                });
            }
            else {
                // Display all geoInstances regardless of it's visibility in the current view / bounds
                await asyncMapArray(geoInstances, 100, (geoInstance) => {
                    geoInstance.setMap(PageScript.map);
                });
            }
        }
        else {
            await asyncMapArray(geoInstances, 100, (geoInstance) => {
                geoInstance.setMap(null);
            });
        }
    }
    /**
     * @deprecated
     * // TODO: Check if this method is rlly deprecated
     */
    async loadSplitParkingSpaces(map, { parseToMap }) {
        const response = await AEngine.get(AGeoService).fetch(MAP_OPTIONS.SplitParkingSpace);
        const { GeoMap } = response.Geo.SplitParkingSpace;
        const geoObjects = Object.values(GeoMap);
        const polyLines = await asyncMapArray(geoObjects, 100, (geoObject) => {
            const { Active, GeoId, Name, Attributes } = geoObject;
            return AGeoUtils.parsePolygon(map, geoObject.Geo, {
                Active, GeoId, Name, Attributes
            }, { parseToMap });
        });
        return polyLines;
    }
    async loadSegmentToSplitParkingSpaceMap() {
        const response = await AEngine.get(AGeoService).fetch(MAP_OPTIONS.SplitParkingSpace);
        const geoObjects = Object.values(response.Geo.SplitParkingSpace.GeoMap);
        const segmentIdToSplitParkingSpaceId = {};
        geoObjects.filter(AGeoUtils.filterInactive).map((geoObject) => {
            const { GeoId, Attributes } = geoObject;
            const { SegmentId } = Attributes;
            if (SegmentId != null) {
                if (!segmentIdToSplitParkingSpaceId.hasOwnProperty(SegmentId)) {
                    segmentIdToSplitParkingSpaceId[SegmentId] = [];
                }
                const splitParkingSpaceId = GeoId;
                segmentIdToSplitParkingSpaceId[SegmentId].push(splitParkingSpaceId);
            }
        });
        return segmentIdToSplitParkingSpaceId;
    }
    async loadSegmentToWaySegmentMap() {
        const response = await AEngine.get(AGeoService).fetch(MAP_OPTIONS.Segment);
        const geoObjects = Object.values(response.Geo.Segment.GeoMap);
        const segToWaySegMap = {};
        geoObjects.filter(AGeoUtils.filterInactive).map((geoObject) => {
            const { GeoId, Attributes } = geoObject;
            const { WaySegmentId, WaySegmentSide } = Attributes;
            const SegmentId = GeoId;
            if (WaySegmentId != null) {
                // if (!segToWaySegMap.hasOwnProperty(SegmentId)) {
                segToWaySegMap[SegmentId] = { WaySegmentId, WaySegmentSide };
                // }
                // segToWaySegMap[WaySegmentId] (SegmentId)
            }
        });
        // waysegment to segment ... segment to parking spaces
        // waysegment - segment: 0.*
        // segment - waysegment: 1.1
        // every segment has exactly 1 waysegment
        return segToWaySegMap;
    }
    async loadWaySegmentToSegmentMap() {
        const response = await AEngine.get(AGeoService).fetch(MAP_OPTIONS.Segment);
        const geoObjects = Object.values(response.Geo.Segment.GeoMap);
        const waySegmentToSegmentId = {};
        geoObjects.filter(AGeoUtils.filterInactive).map((geoObject) => {
            const { GeoId, Attributes } = geoObject;
            const { WaySegmentId, WaySegmentSide } = Attributes;
            if (WaySegmentId != null) {
                if (!waySegmentToSegmentId.hasOwnProperty(WaySegmentId)) {
                    waySegmentToSegmentId[WaySegmentId] = {};
                }
                const SegmentId = GeoId;
                waySegmentToSegmentId[WaySegmentId](SegmentId);
            }
        });
        // waysegment to segment ... segment to parking spaces
        // waysegment - segment: 0.*
        // segment - waysegment: 1.1
        // every segment has exactly 1 waysegment
        return waySegmentToSegmentId;
    }
}

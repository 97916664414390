import { AError } from "../classes/AError.js";
import { AForm } from "../core/form/AForm.js";
import { EVENTS } from "../services/AEventService.js";
import { ALoginPageBase } from "./login_base.js";
export class APage extends ALoginPageBase {
    constructor() {
        super({
            Company: 'SCANaCAR',
            NodeType: 'Scan Auto Dummy', // Scan Dummy
        });
    }
    async init() {
        const { UseUsername, HidePassword, ConcealPassword, UseAuthenticator, UseTeams, EnforceUnits } = globalThis.Config;
        this.setUpTeam({ UseTeams, EnforceUnits })
            .setupUsername({ UseUsername })
            .setupPassword({ HidePassword, ConcealPassword })
            .setupAuthenticator({ UseAuthenticator })
            .setupLogin()
            .setupLinking()
            .show();
    }
    setUpTeam({ UseTeams, EnforceUnits }) {
        if (UseTeams == true) {
            let EnforeUnitId = document.getElementById('Unit');
            for (let EnforceUnitId in EnforceUnits) {
                let option = document.createElement('option');
                option.value = EnforceUnitId;
                option.innerText = EnforceUnits[EnforceUnitId];
                EnforeUnitId?.appendChild(option);
            }
            $('#Unit').on('keypress', (e) => {
                if (e.which == 13) {
                    $('#Username').trigger('focus');
                }
            });
        }
        $('#UnitHolder').toggleClass('hidden', !UseTeams);
        return this;
    }
    setupUsername({ UseUsername }) {
        if (UseUsername == true) {
            $('#UsernameField').attr('style', '');
            $('#Username').on('keypress', (e) => {
                if (e.which == 13) {
                    $('#Password').trigger('focus');
                }
            });
        }
        return this;
    }
    setupPassword(opt) {
        const { UseAuthenticator, HidePassword, ConcealPassword } = {
            HidePassword: opt.HidePassword !== undefined ? opt.HidePassword : true,
            ConcealPassword: opt.ConcealPassword !== undefined ? opt.ConcealPassword : undefined,
            UseAuthenticator: opt.UseAuthenticator !== undefined ? opt.UseAuthenticator : false,
        };
        const hidePassword = (HidePassword == true || ConcealPassword == true);
        const inputType = (hidePassword) ? 'password' : 'text';
        $('#Password').attr('type', inputType);
        $("#Password").on('keypress', (e) => {
            if (e.which == 13) {
                if (UseAuthenticator == true) {
                    $("#AuthenticatorCode").trigger('focus');
                }
                else {
                    $("#Login").trigger("click");
                }
            }
        });
        return this;
    }
    setupAuthenticator({ UseAuthenticator }) {
        $("#AuthenticatorCode").on('keypress', function (e) {
            if (e.which == 13) {
                $("#Login").trigger("click");
            }
        });
        $('#AuthenticatorCodeHolder').css({
            display: UseAuthenticator === true ? 'inline-block' : 'none'
        });
        return this;
    }
    setupLogin() {
        $("#Login").on('click', () => Loading.waitForPromises(this.attemptLogin()));
        return this;
    }
    setupLinking() {
        $('#link').on('click', async (e) => {
            $('#login-form :input').prop('disabled', true);
            // const promise = Loading.waitForEvent(`status->ReadyToEnforce`)
            try {
                await requestService.send('ChangeSessionModeRequest', {
                    SessionMode: 'Link'
                }, {
                    waitForEvent: 'status->ReadyToEnforce'
                });
                this.loginSucceeded();
            }
            catch (err) {
                AError.handle(err);
            }
            $('#login-form :input').prop('disabled', false);
        });
        return this;
    }
    async attemptLogin() {
        $('#login-form').addClass('loader-active');
        $('#login-form :input').prop('disabled', true);
        const creds = AForm.extractFormData($('#login-form'), { ignoreWildcards: true });
        const loginResponse = await requestService.send('LoginRequest', {
            SessionMode: 'Enforce',
            ...creds,
        }, { priority: 5, waitForEvent: EVENTS.STATE_STREAM });
        if (stateService.isLoggedIn(loginResponse)) {
            // _.setUser(loginResponse)
            if (loginResponse.Status !== 'WaitingForVerificationChannel') {
                await this.loginSucceeded();
            }
        }
        else if (stateService.isInvalid()) {
            AError.handleSilent(`Didn't expect Status="${loginResponse.Status}"`);
        }
        $('#login-form :input').prop('disabled', false);
        $('#login-form').removeClass('loader-active');
    }
}
export function render() {
    return ( /*html*/`
    <div id="first-load">
      <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem;"></div>
    </div>
    <div class="container" style="padding-left: .4rem; padding-right: .4rem">
      <div class="columns center-login-form">
        <form id="login-form" class="column col-3 col-md-8 col-sm-10 col-xs-12 col-mx-auto has-loader">
          <div class="loader-container">
            <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem;"></div>
          </div>
          
          <div class="text-center" style="padding-bottom: 10px">
            <span id="label2" class="text-logo text-sm" style="text-transform: none;"></span><br>
            <span id="label1" class="text-logo"></span><br>
          </div>

          <div class="divider text-center" data-content=""></div>
          <div class="col-12 form-group" id="UnitHolder">
            <label class="form-label" for="Unit">Enfore Unit</label>
            <select class="form-input" id="Unit" name="Unit" placeholder="Enforce Unit"> </select>
          </div>
          <div class="col-12 form-group" id="UsernameHolder">
            <label class="form-label" for="Username">User name</label>
            <input class="form-input" type="text" id="Username" name="Username" placeholder="Username">
          </div>
          <div class="col-12 form-group" id="PasswordHolder">
            <label class="form-label" for="Password">Password</label>
            <input class="form-input" type="password" id="Password" name="Password" placeholder="Password">
          </div>
          <div class="col-12 form-group hidden" id="AuthenticatorCodeHolder">
            <label class="form-label" for="AuthenticatorCode">Authenticator Code</label>
            <input class="form-input" type="text" id="AuthenticatorCode" name="AuthenticatorCode" placeholder="AuthenticatorCode">
          </div>

          <div class="divider text-center" data-content=""></div>

          <div class="columns">
            <div class="column col-12 mb-2">
              <button class="btn btn-primary btn-shadow btn-glossy col-12" id="Login">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                Sign-In
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>

            <div class="column col-12 text-center">
              <button id="link" class="btn btn-grey btn-sm col-12">Sign In Using PDA</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  `);
}

import { ParkingPlaceDetections, UniqueDetections } from "./query.js";
export function RequestMapOccupancy(CCCClient, FilterSettings, DetectionsFullRanged) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapOccupancy",
        Query: "select ParkingSpaceCode, CAST(o.Items as JSON) as Items, p.Capacity,p.Name, ST_AsGeoJSON(p.Bounds) as Bounds from (select ParkingSpaceCode, CONCAT('[', GROUP_CONCAT(Items),']') as Items from (select ParkingSpaceCode,StreetSegment, CONCAT('[', GROUP_CONCAT(Cars),']') as Items from  	( 		 		select ParkingSpaceCode,StreetSegmentTimeStamp,StreetSegment, count(*) as Cars from  		( 			select LicensePlate, ParkingSpaceCode,StreetSegmentTimeStamp,StreetSegment from (" + DetectionsFullRanged + ") full where ParkingSpaceCode is not null and if(:Site is null or :site='%', true, Site=:Site) group by LicensePlate, ParkingSpaceCode,StreetSegment, StreetSegmentTimeStamp 		) t1 group by ParkingSpaceCode,StreetSegment, StreetSegmentTimeStamp 	 	) t group by ParkingSpaceCode,StreetSegment) t Group by ParkingSpaceCode) o right join parkingspaces p using(ParkingSpaceCode) where if(:Site is null or :site='%', true, p.AreaCode in (select AreaCode from Areas where Site=:Site) );",
        Params: {
            FromDate: FilterSettings.FromDate,
            ToDate: FilterSettings.ToDate,
            Site: FilterSettings.Site,
        }
    });
}
export function RequestMapParkingSpaces(CCCClient, FilterSettings) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapParkingSpaces",
        Query: "SELECT p.ParkingSpaceCode,:RouteId as RouteId, AreaCode,Capacity, ST_AsGeoJSON(p.Bounds) as Bounds , a.Addition from parkingspaces p left join parkingspacesadditions a on(p.ParkingSpaceCode=a.ParkingSpaceCode and a.RouteId=:RouteId) where if(:Site is null or :Site='%', true, p.AreaCode in (select AreaCode from Areas where Site=:Site) );",
        Params: {
            RouteId: FilterSettings.RouteId,
            Site: FilterSettings.Site,
        }
    });
}
export function RequestMapScans(CCCClient, FilterSettings, DetectionsFullRanged, MaxResults) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapScans",
        Query: "select GpsVehicleBounds,VehicleCenterLatitude,VehicleCenterLongitude,DetectionId,DetectionDeviceId,DetectionTime, LicensePlate,Site, Confidence,Side,CameraIds,GpsPrecision,VehicleMoving,HasParkingRight,ParkingRightType,VerificationResultText as Result, NearMatches,AreaCode,AreaConfidence, ParkingSpaceCode,ParkingSpaceConfidence,StreetSegment,StreetSegmentTimeStamp,ScanDeviceDirection,CardinalDirection,Label from (" + DetectionsFullRanged + ") full where ABS(VehicleSpeed) between :MinSpeed and :MaxSpeed and GpsPrecision between :MinGpsPrecision and :MaxGpsPrecision and IF(:ParkingRightType='%',true, ParkingRightType=:ParkingRightType) and IF(:DeviceName='%',true, DetectionDevice=:DeviceName) and IF(:Site='%',true, Site=:Site) and IF(:VerifyResult='%',true, VerificationResult=:VerifyResult) ORDER BY DetectionId LIMIT :Limit",
        Params: {
            FromDate: FilterSettings.FromDate,
            ToDate: FilterSettings.ToDate,
            DeviceName: FilterSettings.DeviceName,
            ParkingRightType: FilterSettings.ParkingRightType,
            Site: FilterSettings.Site,
            VerifyResult: FilterSettings.VerifyResult,
            MinGpsPrecision: FilterSettings.MinGpsPrecision,
            MaxGpsPrecision: FilterSettings.MaxGpsPrecision,
            MinSpeed: FilterSettings.MinSpeed,
            MaxSpeed: FilterSettings.MaxSpeed,
            Limit: MaxResults, MaxResults
        }
    });
}
export function RequestParkingSpaceMapScans(CCCClient, FilterSettings, DetectionsFullRanged, MaxResults) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapScans",
        Query: "select GpsVehicleBounds,VehicleCenterLatitude,VehicleCenterLongitude,DetectionTime, LicensePlate,Site, Confidence,Side,CameraIds,GpsPrecision,VehicleMoving,HasParkingRight,ParkingRightType,NearMatches,AreaCode,AreaConfidence, ParkingSpaceCode,ParkingSpaceConfidence,StreetSegment,StreetSegmentTimeStamp,ScanDeviceDirection,CardinalDirection from (" + DetectionsFullRanged + ") full inner join(" + ParkingPlaceDetections + ") u using(DetectionId,DetectionDeviceId) where ABS(VehicleSpeed) between :MinSpeed and :MaxSpeed and GpsPrecision between :MinGpsPrecision and :MaxGpsPrecision and IF(:ParkingRightType='%',true, ParkingRightType=:ParkingRightType) and IF(:DeviceName='%',true, DetectionDevice=:DeviceName) and IF(:Site='%',true, Site=:Site) and IF(:VerifyResult='%',true, VerificationResult=:VerifyResult) ORDER BY DetectionId LIMIT :Limit",
        Params: {
            FromDate: FilterSettings.FromDate,
            ToDate: FilterSettings.ToDate,
            DeviceName: FilterSettings.DeviceName,
            ParkingRightType: FilterSettings.ParkingRightType,
            Site: FilterSettings.Site,
            VerifyResult: FilterSettings.VerifyResult,
            MinGpsPrecision: FilterSettings.MinGpsPrecision,
            MaxGpsPrecision: FilterSettings.MaxGpsPrecision,
            MinSpeed: FilterSettings.MinSpeed,
            MaxSpeed: FilterSettings.MaxSpeed,
            Limit: MaxResults, MaxResults
        }
    });
}
export function RequestUniqueMapScans(CCCClient, FilterSettings, DetectionsFullRanged, MaxResults) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapScans",
        Query: "select GpsVehicleBounds,VehicleCenterLatitude,VehicleCenterLongitude,DetectionId,DetectionDeviceId,DetectionTime, LicensePlate,Site, Confidence,Side,CameraIds,GpsPrecision,VehicleMoving,HasParkingRight,ParkingRightType,NearMatches,AreaCode,AreaConfidence, ParkingSpaceCode,StreetSegment,StreetSegmentTimeStamp,StreetSegmentSide,StreetSegmentDirection,ScanDeviceDirection,CardinalDirection,Label from (" + DetectionsFullRanged + ") full inner join(" + UniqueDetections + ") u using(DetectionId,DetectionDeviceId) where ABS(VehicleSpeed) between :MinSpeed and :MaxSpeed and GpsPrecision between :MinGpsPrecision and :MaxGpsPrecision and IF(:ParkingRightType='%',true, ParkingRightType=:ParkingRightType) and IF(:DeviceName='%',true, DetectionDevice=:DeviceName) and IF(:Site='%',true, Site=:Site) and IF(:VerifyResult='%',true, VerificationResult=:VerifyResult) ORDER BY DetectionId LIMIT :Limit",
        Params: {
            FromDate: FilterSettings.FromDate,
            ToDate: FilterSettings.ToDate,
            DeviceName: FilterSettings.DeviceName,
            ParkingRightType: FilterSettings.ParkingRightType,
            Site: FilterSettings.Site,
            VerifyResult: FilterSettings.VerifyResult,
            MinGpsPrecision: FilterSettings.MinGpsPrecision,
            MaxGpsPrecision: FilterSettings.MaxGpsPrecision,
            MinSpeed: FilterSettings.MinSpeed,
            MaxSpeed: FilterSettings.MaxSpeed,
            Limit: MaxResults, MaxResults
        }
    });
}
export function RequestMapRoute(CCCClient, FilterSettings, DetectionsFullRanged) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapRouteGps",
        Query: "select SessionId,GpsTime,Latitude,Longitude from gps where GpsTime>=:FromDate and GpsTime<=:ToDate and DeviceName like :DeviceName and Latitude!=0 and Longitude!=0 and if(:Site='%' or :Site is null, true, ST_Contains((select Bounds from areas where Site=:Site) , ST_GeomFromText(concat ('POINT(',Longitude, ' ', Latitude, ')' ), 4326 ) ) )",
        Params: {
            FromDate: FilterSettings.FromDate,
            ToDate: FilterSettings.ToDate,
            DeviceName: FilterSettings.DeviceName,
            Site: FilterSettings.Site
        }
    });
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapRouteSessionStatus",
        Query: "select SessionId,Time,Status from sessionstatus where SessionId in (SELECT SessionId FROM gps where GpsTime>=:FromDate and GpsTime<=:ToDate and DeviceName like :DeviceName group by SessionId);",
        Params: {
            FromDate: FilterSettings.FromDate,
            ToDate: FilterSettings.ToDate,
            DeviceName: FilterSettings.DeviceName
        }
    });
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapRouteCamStatus",
        Query: "select SessionId,Time,Left_DetectingCount,Right_DetectingCount from camstatus where SessionId in (SELECT SessionId FROM gps where GpsTime>=:FromDate and GpsTime<=:ToDate and DeviceName like :DeviceName group by SessionId);",
        Params: {
            FromDate: FilterSettings.FromDate,
            ToDate: FilterSettings.ToDate,
            DeviceName: FilterSettings.DeviceName
        }
    });
}
export function LabelDetection(Value, Index) {
    MapScansResult[Index].Label = Value;
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "Update",
        Query: "UPDATE detections set Label=:Label WHERE DetectionId=:DetectionId and DetectionDeviceId=:DetectionDeviceId;",
        Params: {
            Label: MapScansResult[Index].Label,
            DetectionId: MapScansResult[Index].DetectionId,
            DetectionDeviceId: MapScansResult[Index].DetectionDeviceId
        }
    });
}
export function AddToParkingSpace(Value, Index) {
    MapParkingSpaceResult[Index].Addition = Value;
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "Update",
        Query: "INSERT INTO parkingspacesadditions (RouteId,ParkingSpaceCode,Addition) VALUES (:RouteId,:ParkingSpaceCode,:Addition) ON DUPLICATE KEY UPDATE Addition=:Addition;",
        Params: {
            RouteId: MapParkingSpaceResult[Index].RouteId,
            ParkingSpaceCode: MapParkingSpaceResult[Index].ParkingSpaceCode,
            Addition: MapParkingSpaceResult[Index].Addition
        }
    });
}
export function ShowMapParkingSpaces(QueryReponse, map, Bounds, InfoWindow) {
    MapParkingSpaceResult = [];
    var BoundsIndex = QueryReponse.Columns.indexOf("Bounds");
    var ParkingSpaceCodeIndex = QueryReponse.Columns.indexOf("ParkingSpaceCode");
    var CapacityIndex = QueryReponse.Columns.indexOf("Capacity");
    var AdditionIndex = QueryReponse.Columns.indexOf("Addition");
    var RouteIdIndex = QueryReponse.Columns.indexOf("RouteId");
    for (var i = 0; i < QueryReponse.Rows.length; i++) {
        var CurrentRow = QueryReponse.Rows[i];
        var ParkingSpaceBounds = CurrentRow[BoundsIndex].coordinates;
        ;
        var ParkingSpaceCode = CurrentRow[ParkingSpaceCodeIndex];
        var Addition = CurrentRow[AdditionIndex];
        var Capacity = CurrentRow[CapacityIndex];
        var RouteId = CurrentRow[RouteIdIndex];
        var ParkingSpacePaths = [];
        var LatLonAvg = {
            lat: 0,
            lng: 0
        };
        var LatLonCount = 0;
        for (var r = 0; r < ParkingSpaceBounds.length; r++) {
            var Ring = [];
            for (var p = 0; p < ParkingSpaceBounds[r].length; p++) {
                var LatLon = {
                    lat: ParkingSpaceBounds[r][p][1],
                    lng: ParkingSpaceBounds[r][p][0]
                };
                LatLonCount++;
                LatLonAvg.lat += LatLon.lat;
                LatLonAvg.lng += LatLon.lng;
                Bounds.extend(LatLon);
                Ring.push(LatLon);
            }
            ParkingSpacePaths.push(Ring);
        }
        LatLonAvg.lat /= LatLonCount;
        LatLonAvg.lng /= LatLonCount;
        if (ParkingSpacePaths.length == 1) {
            ParkingSpacePaths = ParkingSpacePaths[0];
        }
        var PSColor = (Addition === undefined || Addition == null || Addition.length <= 0) ? '#ffffaa' : '#0000FF';
        var ParkingSpace = new google.maps.Polygon({
            paths: ParkingSpacePaths,
            strokeColor: '#888888',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: PSColor,
            fillOpacity: 0.5,
            zIndex: 1.,
            // position: LatLonAvg,
        });
        ParkingSpace.Addition = Addition;
        ParkingSpace.Index = MapParkingSpaceResult.length;
        ParkingSpace.RouteId = RouteId;
        ParkingSpace.ParkingSpaceCode = ParkingSpaceCode;
        ParkingSpace.String = "ParkingSpaceCode : " + ParkingSpaceCode + "<br>"
            + "Capacity  : " + Capacity + " Cars<br>";
        ParkingSpace.setMap(map);
        MapParkingSpaceResult.push(ParkingSpace);
        google.maps.event.addListener(ParkingSpace, "click", function (event) {
            InfoWindow.close();
            // @ts-ignore
            InfoWindow.setContent(this.String + "Add Scans : <input type='number' value='" + this.Addition + "' onchange='AddToParkingSpace(this.value," + this.Index + ");' autofocus></input>");
            // @ts-ignore
            this.position = event.latLng;
            // @ts-ignore
            InfoWindow.open(map, this);
        });
        google.maps.event.addListener(ParkingSpace, "rightclick", function (event) {
            // @ts-ignore
            var ZIndex = this.zIndex;
            if (ZIndex === null || ZIndex === undefined) {
                ZIndex = 0;
            }
            else {
                ZIndex = ZIndex - 1;
            }
            // @ts-ignore
            this.setOptions({ zIndex: ZIndex });
        });
    }
    return MapParkingSpaceResult;
}
export function GetFilterArea() {
    return (FilterSettings?.Area != "%" && FilterSettings?.Area != "Unknown") ? FilterSettings?.Area : null;
}
export function convertGeoInstancesToMap(arrayOfPolyLines, keyMapper, valueMapper) {
    const output = {};
    if (valueMapper) {
        arrayOfPolyLines.map((polyline) => {
            const key = keyMapper(polyline);
            output[key] = valueMapper(polyline);
        });
    }
    else {
        arrayOfPolyLines.map((polyline) => {
            const key = keyMapper(polyline);
            output[key] = polyline;
        });
    }
    return output;
}
export function AIsLatLngValid(input) {
    const arr = (input instanceof google.maps.LatLng) ? [input.lat(), input.lng()] : input;
    const maxValue = Math.max.apply(this, [0, ...arr]);
    if (maxValue === 0 || isNaN(maxValue)) {
        return false;
    }
    for (const item of arr) {
        if (item === null || item === undefined) {
            return false;
        }
    }
    return true;
}

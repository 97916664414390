import { AEngine } from "../../core/AEngine.js";
import { AGeoUtils } from "../../core/maps/AGeoUtils.js";
import { AComRouteService } from "../../services/AComRouteService.js";
import { toast } from "../../utils/toasts.js";
import { mergeDeep } from "../../utils/tools.js";
import { AScanDevice } from "./AScanDevice.js";
export class ARouteScanDevice extends AScanDevice {
    constructor(options) {
        super(options);
        this.events = {};
    }
    loadRoute(RouteName, opt) {
        const Data = AEngine.get(AComRouteService).getRoute(RouteName);
        if (!Data) {
            return Alerts.noResults();
        }
        if (script?.map == null) {
            debugger;
            return;
        }
        const RouteSegments = Data.RouteSegments;
        const polylines = RouteSegments.map((seg) => {
            const geoObject = {
                "type": "LineString",
                "srid": 4326,
                "coordinates": seg.Line
            };
            const wayseg = opt.waySegmentsMap[Math.abs(seg.WaySegmentId)];
            const mergedData = mergeDeep({}, wayseg.data, seg);
            const polyline = AGeoUtils.parseLineString(this.getMap(), geoObject, mergedData, { parseToMap: true });
            return polyline;
        });
        coreMapService.addClickListeners(polylines);
        for (let wayseg of polylines) {
            this.toggleWaysegment(wayseg);
        }
        toast({ msg: 'ScanDevice Route Set!', timeout: 3000 });
        this.routeName = RouteName;
    }
    recalcRoute() {
        super.recalcRoute();
    }
    reset() {
        this.routeItemIndex = 0;
        coreMapService.unload(this.selected);
        this.routeName = undefined;
        this.selected = [];
        this.recalcRoute();
    }
    onStart(cbFn) {
        this.events['start'] = cbFn;
    }
    onStop(cbFn) {
        this.events['stop'] = cbFn;
    }
    createRouteSessionId() {
        return Date.now().toString().padEnd(17, "0");
    }
    startBroadcasting(reason) {
        if (this.events['start'])
            this.events['start']('none');
        if (!this.routeSessionId) {
            this.routeSessionId = this.createRouteSessionId();
            if (this.routeName)
                AEngine.get(AComRouteService).notifyRouteStarted(this.routeName, this.routeSessionId);
        }
        super.startBroadcasting(reason);
    }
    stopBroadcasting(reason) {
        super.stopBroadcasting(reason);
        if (reason === 'finished') {
            this.routeSessionId = undefined;
            if (this.routeName)
                AEngine.get(AComRouteService).notifyRouteStopped(this.routeName);
        }
        if (this.events['stop'])
            this.events['stop'](reason);
    }
}

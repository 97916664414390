import { AError } from "../classes/AError.js";
import { createMap } from "../core/maps/ACoreMapService.js";
import { MAP_OPTIONS } from "../core/maps/AMapStructs.js";
import { addTabListenersFind } from "../utils/tabs.js";
export class APage {
    // segments: ASegment[]
    // waySegments: APolyline[]
    // waySegmentsMap: { [key: string]: APolyline }
    // parkingSpaces: APolygon[]
    // splitParkingSpaces: APolygon[] 
    // splitParkingSpacesDataMap: { [key: string]: APolygon }
    // waySegmentToSideSplitParkingSpaceMap: { [key: number]: { [key: number]: number[] } }
    constructor() {
        const defaultCoords = geoService.defaultCoordinates;
        this.startPos = defaultCoords.center.toJSON();
        this.startBounds = defaultCoords.bounds.toJSON();
        this.startPosition = new google.maps.LatLng(this.startPos);
        this.map = createMap('#map', {
            center: this.startPosition,
            zoom: 16
        });
    }
    async init() {
        addTabListenersFind($('#AjaxContent'));
        coreMapService.prepareMapItems(MAP_OPTIONS.All).catch(AError.handle);
        this.handleMapEvents();
        $('.loading-page-init').fadeOut();
    }
    handleMapEvents() {
    }
}
export function css() {
    return ( /*html*/`
    <style>
      @font-face {
        font-family: Kenteken;
        src: url('/font/Kenteken.ttf') format('truetype');
        /* src: url('/font/Kenteken.eot'); /* IE9 Compat Modes */
        src: url('/font/Kenteken.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */
          url('/font/Kenteken.woff') format('woff'),
          /* Pretty Modern Browsers */
          url('/font/Kenteken.ttf') format('truetype'),
          /* Safari, Android, iOS */
          url('/font/Kenteken.svg#svgFontName') format('svg');
        /* Legacy iOS */
        font-weight: normal;
      }
      .aci-tabs {
        background: #f1f0f0;
      }
      [tabview] {
        height: 100%;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        overflow-y: auto;
      }
      .aci-tabs {
        /*height: 56px; */
      }
      .views {
        height: calc(100% - 56px)
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="wrapper lg columns col-gapless">
      <div class="column col-12" style="height: 100%; background: #f8f9fa">
        <div id="map" class="aci-map"></div>
      </div>
    </div>
  `);
}

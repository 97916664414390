import { createMap } from "../core/maps/ACoreMapService.js";
import { MAP_OPTIONS } from "../core/maps/AMapStructs.js";
import { EVENTS } from "../services/AEventService.js";
import { getDetectionFromCache, SetUserPreset } from "../utils/getDetectionFromCache.js";
import { genMarker } from "../utils/scanauto-geo-tools.js";
import { CreateLPImage, readFiles } from "../utils/scanauto-tools.js";
import { AInputDate, AInputTimeSeconds, waitForChromeFrame } from "../utils/tools.js";
function getRandomLicensePlate() {
    const PROVINCIES = {
        GRONINGEN: 'A',
        FRIENSLAND: 'B',
        OVERIJSSEL: 'E',
        NORTH_HOLLAND: ['G', 'GZ', 'GX'],
        SOUTH_HOLLAND: ['H', 'HZ', 'HX'],
        ZEELAND: 'K',
        GELDERLAND: 'M',
        NORTH_BRABANT: 'N',
        UTRECHT: 'L',
        LIMBURG: 'P'
    };
    let AREA_CODE = PROVINCIES['NORTH_HOLLAND'];
    if (Array.isArray(AREA_CODE)) {
        const index = Math.floor(Math.random() * AREA_CODE.length);
        AREA_CODE = AREA_CODE[index];
    }
    const regex = /X/g;
    const FORMAT = (`9-XX-999`); // License Plate Format from 2016 till 2019
    // @ts-ignore
    let output = FORMAT.replace(/9/g, () => Math.floor(Math.random() * 10));
    let match = regex.exec(output);
    let charIndex = 0;
    while (match != null) {
        let explode = output.split('');
        explode[match.index] = AREA_CODE[charIndex++ % AREA_CODE.length];
        output = explode.join('');
        match = regex.exec(output);
    }
    return output;
}
export class APage {
    constructor() {
        this.presetLpImage = '';
        this.presetCarImage = '';
        this.presetOvImages = [];
        this.index = 1;
        this.prefix = 'TEST';
        this.date = new Date();
        const defaultCoords = geoService.defaultCoordinates;
        // this.startPos = defaultCoords.center.toJSON()
        // this.startPosition = new google.maps.LatLng(this.startPos)
        this.map = createMap('#map', {
            center: defaultCoords.center,
            zoom: 16,
        });
        Events.on(EVENTS.GEO_OBJECT_CLICKED, (event) => {
            this.setFormCoordinates(event.pos);
            this.updateMarker(event.pos);
        });
        google.maps.event.addListener(this.map, 'click', (event) => {
            this.setFormCoordinates(event.latLng.toJSON());
            this.updateMarker(event.latLng);
        });
    }
    setFormCoordinates(latLng) {
        $('#Latitude').val(latLng.lat);
        $('#Longitude').val(latLng.lng);
        $('#Latitude, #Longitude').trigger('change');
    }
    calcStartingPos() {
        const { lat, lng } = geoService.defaultCoordinates.center.toJSON();
        return {
            lat: Number(localStorage.getItem('lat')) || lat,
            lng: Number(localStorage.getItem('lng')) || lng,
        };
    }
    async init() {
        // this.parkingSpacesOnMap = await geoService.load(MAP_OPTIONS.ParkingSpaces, {
        //   parseToMap: true,
        //   addClickListener: true,
        // })
        // coreMapService.toggleMapLabels(this.map)
        await coreMapService.prepareMapItems(MAP_OPTIONS.All, {
            allowExport: false,
        });
        // await geoService.load<APolygon>(this.map, MAP_OPTIONS.ParkingSpace, { parseToMap: true, addClickListener: true })
        const markerPos = this.calcStartingPos();
        this.detectionIndicator = genMarker({
            position: markerPos,
            map: this.map,
            type: "ExternalDevice"
        });
        this.initDomEvents();
        this.updateFields({ ...markerPos, scd: 90.1, side: '%' });
    }
    initDomEvents() {
        $("#DetectionDate").on('change', function () {
            var Index = 0;
            var DateTime = new Date($("#DetectionDate").val() + " " + $("#DetectionTime").val());
            var DetectionId = (DateTime.getTime() - 946684800000);
            var DetectionAddition = Index.toString(16).padStart(4, '0');
            $("#DetectionId").val("0x" + DetectionId.toString(16) + DetectionAddition);
        });
        $("#DetectionTime").on('change', function () {
            var Index = 0;
            var DateTime = new Date($("#DetectionDate").val() + " " + $("#DetectionTime").val());
            var DetectionId = (DateTime.getTime() - 946684800000);
            var DetectionAddition = Index.toString(16).padStart(4, '0');
            $("#DetectionId").val("0x" + DetectionId.toString(16) + DetectionAddition);
        });
        $('#Latitude').on('change keyup keydown', async () => {
            await waitForChromeFrame();
            const m = /\d+(?:\.\d+)?/.exec($('#Latitude').val() || '');
            const v = m?.length ? m[0] : '0';
            $('#Latitude').val(v);
            this.updateMarker();
        });
        $('#Longitude').on('change keyup keydown', async () => {
            await waitForChromeFrame();
            const m = /\d+(?:\.\d+)?/.exec($('#Longitude').val() || '');
            const v = m?.length ? m[0] : '0';
            $('#Longitude').val(v);
            this.updateMarker();
        });
        $('#randomLicensePlates').on('change', () => this.updateFields());
        $('#mode').on('change', (e) => {
            this.presetLpImage = '';
            this.presetCarImage = '';
            this.presetOvImages = [];
            $('#override-lp').val('').trigger('change');
            $('#override-overview').val('').trigger('change');
            $('#upload-container').toggleClass('hidden', !this.useUploadedPreset);
            if (this.usePresets) {
                this.updateFields();
            }
        });
        const $lpBtn = $('#upload-lp-btn');
        const $ovBtn = $('#upload-overview-btn');
        $('#override-lp').on('change', async () => {
            const files = await readFiles({ selector: '#override-lp' });
            this.presetLpImage = files[0] || '';
            $lpBtn.find('span').text(files.length);
            $lpBtn.toggleClass('btn-grey', files.length === 0);
            $lpBtn.toggleClass('btn-success', files.length > 0);
        });
        $('#override-overview').on('change', async () => {
            const files = await readFiles({ selector: '#override-overview' });
            this.presetOvImages = files;
            $ovBtn.find('span').text(files.length);
            $ovBtn.toggleClass('btn-grey', files.length === 0);
            $ovBtn.toggleClass('btn-success', files.length > 0);
        });
        $('#Send').on('click', e => this.send());
    }
    get usePresets() {
        return $('#mode').val() === 'usePresets';
    }
    get useUploadedPreset() {
        return $('#mode').val() === 'upload';
    }
    get useRandomLP() {
        return $('#randomLicensePlates').prop('checked');
    }
    updateMarker(opt) {
        if (!opt) {
            opt = new google.maps.LatLng(Number($('#Latitude').val()), Number($('#Longitude').val())).toJSON();
        }
        console.log(opt);
        // if (this.marker.getPosition()?.equals(opt))
        this.detectionIndicator.setPosition(opt);
    }
    updateFields(options) {
        let { lat, lng, scd, side } = Object.assign({}, options);
        this.date = new Date();
        var DetectionId = (this.date.getTime() - 946684800000);
        var DetectionAddition = this.index.toString(16).padStart(4, '0');
        if (lat)
            $('#Latitude').val(lat);
        if (lng)
            $('#Longitude').val(lng);
        if (this.usePresets) {
            const cachedDetection = getDetectionFromCache();
            this.presetLpImage = cachedDetection.LPImage;
            $("#Side").val(cachedDetection.Side);
            $("#LicensePlate").val(cachedDetection.LicensePlate);
            $("#CountryCode").val(cachedDetection.CountryCode);
            $("#Confidence").val(cachedDetection.Confidence);
        }
        else {
            if (scd)
                $("#ScanDeviceDirection").val(scd);
            if (side)
                $("#Side").val(side);
            $("#LicensePlate").val(this.useRandomLP ? getRandomLicensePlate() : this.getNextLicensePlate());
            $("#CountryCode").val("N");
            $("#Confidence").val("0.985");
        }
        $("#DetectionId").val("0x" + DetectionId.toString(16) + DetectionAddition);
        $("#DetectionDate").val(AInputDate(this.date));
        $("#DetectionTime").val(AInputTimeSeconds(this.date));
    }
    getNextLicensePlate() {
        const currVal = $("#LicensePlate").val();
        const arr = currVal.match(/[0-9]+$/);
        if (arr !== null && arr.length) {
            try {
                const prefix = currVal.substring(0, currVal.length - arr[0].length);
                let num = parseInt(arr[0]);
                return prefix + (++num + '').padStart(arr[0].length, '0');
            }
            catch (err) {
                console.error(err);
            }
        }
        return this.prefix + (this.index + '').padStart(2, '0');
    }
    getDetectionFromInput() {
        return {
            IsGeneratedLicenseplate: true,
            DetectionId: $("#DetectionId").val(),
            DetectionTime: (new Date($("#DetectionDate").val() + " " + $("#DetectionTime").val())).toJSON(),
            LicensePlate: ($("#LicensePlate").val() || '').replace(/-+/g, '').toUpperCase(),
            CountryCode: $("#CountryCode").val(),
            Confidence: $("#Confidence").val(),
            ScanDeviceGps: {
                Latitude: $("#Latitude").val(),
                Longitude: $("#Longitude").val()
            },
            VehicleGps: {
                Latitude: $("#Latitude").val(),
                Longitude: $("#Longitude").val()
            },
            GpsPrecision: 1.,
            ScanDeviceDirection: $("#ScanDeviceDirection").val(),
            Side: $("#Side").val() === '%' ? ['L', 'R'][Math.floor(Math.random() * 2)] : $('#Side').val(),
            LPImage: this.presetLpImage || CreateLPImage(($("#LicensePlate").val() || '').toUpperCase())
        };
    }
    // getDetectionData() {
    //   const detectionData = (this.usePresets) ? getDetectionFromCache() : this.getDetectionFromInput()
    //   console.log('DetectionData', detectionData)
    //   return detectionData
    // }
    send() {
        localStorage.setItem('lat', $("#Latitude").val());
        localStorage.setItem('lng', $("#Longitude").val());
        if (!this.usePresets) {
            this.index++;
        }
        globalThis.sendImagesAsPng = $('#SendImagesAsPng').prop('checked');
        const detectionData = this.getDetectionFromInput();
        if (this.useUploadedPreset) {
            delete detectionData.IsGeneratedLicenseplate;
            SetUserPreset(detectionData.LicensePlate, {
                imageresponse: {
                    LP: {
                        "Success": this.presetLpImage ? true : false,
                        "ImageBuffer": this.presetLpImage?.split(';base64,').pop() || '',
                    },
                    CAR: {
                        "Success": this.presetCarImage ? true : false,
                        "ImageBuffer": this.presetCarImage?.split(';base64,').pop() || '',
                    },
                    OVERVIEW: {
                        "Success": this.presetOvImages ? true : false,
                        "ImageBuffer": this.presetOvImages?.map(base64 => base64.split(';base64,').pop() || ''),
                    },
                }
            });
        }
        console.log('detectionData', detectionData);
        CCCClient.SendMessage("DetectionStream", 1, detectionData);
        this.updateFields();
    }
}
export function css() {
    return ( /*html*/`
    <style>
      @font-face {
        font-family: Kenteken;
        src: url('/font/Kenteken.ttf') format('truetype');
        /*
      src: url('/font/Kenteken.eot'); /* IE9 Compat Modes */
        src: url('/font/Kenteken.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */
          url('/font/Kenteken.woff') format('woff'),
          /* Pretty Modern Browsers */
          url('/font/Kenteken.ttf') format('truetype'),
          /* Safari, Android, iOS */
          url('/font/Kenteken.svg#svgFontName') format('svg');
        /* Legacy iOS */
        font-weight: normal;
      }

      .form-horizontal {
        height: 100%;
        padding: 0;
        overflow-y: auto;
      }

      .form-horizontal .checkbox-label {
        line-height: 2.2em;
      }

      .form-content {
        height: calc(100% - 52px);
        overflow-y: auto;
      }

      .form-footer {
        height: 52px;
        box-shadow: rgb(0 0 0 / 59%) 1px 2px 4px;
        background: #0000000a;
      }

      .form-footer > .btn {
        margin-top: 8px;
      }

      #LicensePlate {
        text-transform: uppercase;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="columns col-gapless full-height" style="position: relative">
      <div class="column col-4 full-height" style="position: relative;">
        <div class="loading-page-init">
          <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem; height: 100%;"></div>
        </div>
        <div class="form-horizontal full-height">
          <div class="column form-content">

            <div class="form-group mt-2">
              <div class="col-4 col-sm-12">
                <label for="mode" class="checkbox-label">Mode</label>
              </div>
              <div class="col-8 col-sm-12">
                <select id="mode" class="form-select">
                  <option value="%">Use Generated Image</option>
                  <option value="usePresets">Use Random Images</option>
                  <option value="upload">Upload Images</option>
                </select>
              </div>

              <div id="upload-container" class="hidden col-8 col-ml-auto">
                <div class="mb-2"></div>
                <label id="upload-lp-btn" for="override-lp" class="btn btn-grey full-width">Upload License Plate (<span>0</span>)</label>
                <input id="override-lp" class="hidden" type="file">
                <label id="upload-overview-btn" for="override-overview" class="btn btn-grey full-width">Upload Overview Images (<span>0</span>)</label>
                <input id="override-overview" class="hidden" type="file" multiple="true">
              </div>
            </div>

            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label for="randomLicensePlates" class="checkbox-label">Random license plates</label>
              </div>
              <div class="col-8 col-sm-12">
                <label class="form-switch">
                  <input type="checkbox" id="randomLicensePlates">
                  <i class="form-icon"></i>
                </label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label for="SendImagesAsPng" class="checkbox-label">Send Images as Png</label>
              </div>
              <div class="col-8 col-sm-12">
                <label class="form-switch">
                  <input type="checkbox" id="SendImagesAsPng" checked="checked">
                  <i class="form-icon"></i>
                </label>
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label"> DetectionId </label>
              </div>
              <div class="col-8 col-sm-12">
                <input class="form-input" type="text" id="DetectionId" readonly>
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label"> DetectionTime </label>
              </div>
              <div class="col-8 col-sm-12">
                <div class="columns">
                  <div class="column col-6">
                    <input class="form-input" type="date" id="DetectionDate">
                  </div>
                  <div class="column col-6">
                    <input class="form-input" type="time" id="DetectionTime" step=".001">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label"> LicensePlate </label>
              </div>
              <div class="col-8 col-sm-12">
                <input class="form-input" type="text" id="LicensePlate" style="font-family: Kenteken;" value="TEST01">
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label"> Confidence </label>
              </div>
              <div class="col-8 col-sm-12">
                <input class="form-input" type="number" id="Confidence" step="any">
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label"> CountryCode </label>
              </div>
              <div class="col-8 col-sm-12">
                <input class="form-input" type="text" id="CountryCode">
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label"> Latitude </label>
              </div>
              <div class="col-8 col-sm-12">
                <input class="form-input" type="text" id="Latitude" step="1">
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label"> Longitude </label>
              </div>
              <div class="col-8 col-sm-12">
                <input class="form-input" type="text" id="Longitude" step="1">
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label">Scan Device Direction </label>
              </div>
              <div class="col-8 col-sm-12">
                <input class="form-input" type="number" id="ScanDeviceDirection" step="0.1">
              </div>
            </div>
            <div class="form-group">
              <div class="col-4 col-sm-12">
                <label class="form-label">Detection Side</label>
              </div>
              <div class="col-8 col-sm-12">
                <select class="form-input" id="Side">
                  <option value="%">Random</option>
                  <option value="L">Left</option>
                  <option value="R">Right</option>
                </select>
              </div>
            </div>
          </div>

          <div class="column form-footer">
            <button id="Send" class="btn btn-primary full-width">
              <i class="fa-regular fa-car-tilt"></i>
              Create Detection
            </button>
          </div>

        </div>
      </div>
      <div class="column col-8">
        <div id="map" class="aci-map"></div>
      </div>
    </div>
  `);
}

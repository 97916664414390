import { ALoopTimer } from "../classes/ALoopTimer.js";
export class AGpsBufferTimer extends ALoopTimer {
    reloadTimeout() { super.changeTimeout(this.getTimeout()); }
    constructor({ device, getTimeout }) {
        super(() => this.addGpsPointToBuffer(), { timeout: getTimeout(), loopLifeCycle: 'PAGE' });
        this.getTimeout = getTimeout;
        this.device = device;
        this.buffer = {
            Points: [],
            Times: [],
            Directions: [],
        };
    }
    start() {
        this.buffer = {
            Points: [],
            Times: [],
            Directions: [],
        };
        return super.start();
    }
    addGpsPointToBuffer() {
        const { lat, lng } = this.device.pos;
        const direction = Math.round(Math.random() * 360.0 * 10) / 10;
        const gpsPoint = [lng, lat];
        const gpsTime = new Date().toJSON();
        this.buffer.Points.push(gpsPoint);
        this.buffer.Times.push(gpsTime);
        this.buffer.Directions.push(direction);
        Gps.setData({ Latitude: lat, Longitude: lng, GpsTime: new Date().toJSON() });
    }
    extractBuffer() {
        if (this.buffer.Points.length === 0) {
            return null;
        }
        const buffer = this.buffer;
        this.buffer = {
            Points: [buffer.Points[buffer.Points.length - 1]],
            Times: [buffer.Times[buffer.Times.length - 1]],
            Directions: [buffer.Directions[buffer.Directions.length - 1]],
        };
        console.log('extractBuffer', buffer, this.buffer);
        return buffer;
    }
}

import { distance, distanceGoogleMaps, genMarker } from "../../utils/scanauto-geo-tools.js";
import { WAYSEGMENT_GREY } from "../../utils/scanauto-tools.js";
import { AGeoUtils } from "../../core/maps/AGeoUtils.js";
import { AFakeGpsDeviceBase } from "./AFakeGpsDeviceBase.js";
import { toast } from "../../utils/toasts.js";
import { ARound, lerp } from "../../utils/tools.js";
import { ANavigationTimer } from "../../timers/ANavigationTimer.js";
export class AScanDevice extends AFakeGpsDeviceBase {
    get routeProgress() {
        if (this.state === 'finished') {
            return 100.0;
        }
        const currPerc = this.routeItemIndex * 100 / this.route.length;
        const nextPerc = Math.min(100, (this.routeItemIndex + 1) * 100 / this.route.length);
        const subp = this.getTimer(ANavigationTimer).progression;
        const output = lerp(currPerc, nextPerc, subp);
        let magProgress = 0;
        for (let i = 0; i < Math.min(this.routeItemIndex, this.route.length - 1); i++) {
            magProgress += this.route[i].magnitude;
        }
        // if (this.routeItemIndex+1 < this.route.length) {
        magProgress += subp * (this.route[Math.min(this.routeItemIndex + 1, this.route.length - 1)]?.magnitude ?? 0);
        // } else {
        //   magProgress += (subp * this.route[])
        // }
        // magProgress +=  * (this.route[this.routeItemIndex+1]?.magnitude ?? 0)
        const drivenPerc = magProgress * 100 / this.routeMagnitude;
        console.log('route progress: \t', output.toFixed(2), drivenPerc.toFixed(2).padStart(6, ' '));
        return drivenPerc;
    }
    get speed() {
        if (!this.isRunning()) {
            return 0.0;
        }
        let val;
        if (this.cfg.speedDeviation !== undefined) {
            val = (this.cfg.targetSpeed - this.cfg.speedDeviation) + (Math.random() * this.cfg.speedDeviation * 2);
        }
        else {
            val = this.cfg.targetSpeed;
        }
        return Math.max(val, 0);
    }
    constructor(options) {
        super(options);
        this.route = [];
        this.routeMagnitude = 1;
        this.routeItemIndex = 0;
        this.cfg = AScanDevice.defaultConfigurableOptions();
        this.setConfigurableOptions(options);
        this.routeItemIndex = 0;
        this.selected = [];
        this.route = [];
        this.detectionIndicator = genMarker({
            position: new google.maps.LatLng(0, 0),
            map: PageScript.map,
        });
    }
    static defaultConfigurableOptions() {
        return {
            detectionIndex: 1,
            inheritStreetMaxSpeed: false,
            detectInterval: 1000,
            targetSpeed: 30,
            speedDeviation: 5,
            interpolationSpeed: 50,
            gpsStateInterval: 5000,
            gpsBufferInterval: 100,
            detectionPrefix: globalThis.prefix || 'TEST',
            centerVehicle: false,
            sendImagesAsPng: true,
            camStateInterval: 500,
        };
    }
    setConfigurableOptions(options) {
        Object.keys(this.cfg).map(key => {
            if (options[key] !== undefined) {
                this.cfg[key] = options[key];
            }
        });
        this.reloadTimeouts();
    }
    getConfigurableOptions() {
        return this.cfg;
    }
    recalcRoute() {
        this.route = this.calcRoute();
        this.routeMagnitude = this.route.map(p => p.magnitude).reduce((a, b) => a + b, 0) ?? 1;
        Events.tryInvoke('SELECTION_CHANGED');
    }
    get waySegmentToSideSplitParkingSpaceMap() {
        return PageScript.waySegmentToSideSplitParkingSpaceMap;
    }
    calcRoute() {
        // TODO: Implement Jesse's routes
        const waySegmentPoints = this.selected.map((entry, i) => {
            const sides = Object.keys(this.waySegmentToSideSplitParkingSpaceMap[entry.data.GeoId] || {}).map(key => Number(key));
            let path = AGeoUtils.convertPathToArray(entry.getPath());
            let magnitude = 0;
            if (path.length > 1) {
                for (var i = 1; i < path.length; i++) {
                    magnitude += distanceGoogleMaps(path[i], path[i - 1]);
                }
            }
            else {
                magnitude = 1;
            }
            return path.map((latLng) => ({
                magnitude,
                latLng,
                selectionIndex: i,
                maxSpeed: Number(entry.data.Attributes.maxspeed) || Number.NaN,
                waySegment: entry,
                sides: sides,
                deadEnd: (sides.length === 1 && sides[0] === 0)
            }));
        });
        if (waySegmentPoints.length === 0) {
            return [];
        }
        // Return sorted route
        return waySegmentPoints.reduce((prev, curr, i) => {
            if (curr.length > 1) {
                const prevFirst = prev[0], prevLast = prev[prev.length - 1];
                const currFirst = curr[0], currLast = curr[curr.length - 1];
                const distanceToFirst = distance(prevLast.latLng, currFirst.latLng);
                const distanceToLast = distance(prevLast.latLng, currLast.latLng);
                if (distanceToFirst > distanceToLast) {
                    curr.reverse();
                }
                if (currFirst.deadEnd === true || currLast.deadEnd === true) {
                    curr = curr.concat([...curr].reverse());
                }
            }
            return prev.concat(curr);
        });
    }
    /**
     * If the route doesn't contain the waysegment, it'll add it,
     * Otherwise the waysegment will be removed from the route collection
     * @returns whether the waysegment has been added to the route
     */
    toggleWaysegment(waysegment) {
        const index = this.selected.indexOf(waysegment);
        const addedToRoute = (index === -1);
        if (addedToRoute) {
            this.selected.push(waysegment);
        }
        else {
            this.selected.splice(index, 1);
        }
        this.recalcRoute();
        return addedToRoute;
    }
    resetWaysegments() {
        this.selected.map((waysegment) => {
            waysegment.setOptions({ strokeColor: WAYSEGMENT_GREY });
        });
        this.selected = [];
        this.recalcRoute();
    }
    reset() {
        this.resetWaysegments();
        this.routeItemIndex = 0;
    }
    get waysegments() {
        return this.selected;
    }
    get routeItems() {
        return this.route;
    }
    get currentRouteItem() {
        if (this.route.length === 0) {
            return {
                magnitude: 1,
                latLng: this.pos,
                selectionIndex: 0,
                maxSpeed: Number.NaN,
                sides: [-1, 1],
                deadEnd: false
            };
        }
        return this.route[this.routeItemIndex % this.route.length];
    }
    get targetPos() {
        return this.currentRouteItem.latLng;
    }
    selectNextWaysegment(increment) {
        console.log(`route progress: ${ARound(this.routeProgress, 2)}%`);
        this.routeItemIndex += increment;
        Events.tryInvoke('SCANDEVICE_POS_INDEX_CHANGED');
        const hasReachedEnd = this.routeItemIndex > 0 && (this.routeItemIndex % this.route.length) === 0;
        if (hasReachedEnd) {
            this.reachedEnd();
        }
    }
    reachedEnd() {
        this.stopBroadcasting('finished');
        if (!this.stopWhenRouteFinished) {
            this.startBroadcasting('running');
        }
    }
    listenForNextWaysegment(callbackFn) {
        Events.on('SCANDEVICE_POS_INDEX_CHANGED', callbackFn);
    }
    listenForNextStep(callbackFn) {
        Events.on('SELECTION_CHANGED', callbackFn);
    }
    setStatistics(statistics) {
        this.statistics = statistics;
    }
    printStatistics() {
        const { simple, precise, distanceToTarget } = this.statistics;
        console.log(`
                \t\t\tSimple   \tPrecise
      Velocity: \t\t\t${simple.kmPerHour.toPrecision(5)}\t\t${precise.kmPerHour.toPrecision(5)}
      Distance: \t\t\t${simple.distanceInMeters.toPrecision(5)}\t\t${precise.distanceInMeters.toPrecision(5)}
      DistanceToTarget: \t${distanceToTarget.toPrecision(5)}
      TargetSpeed: \t\t${this.config('targetSpeed').toPrecision(5)}
    `);
    }
    teleportToStart() {
        this.position = this.targetPos;
    }
    reloadTimeouts() {
        this.timers.map(timer => {
            // Reload all timeouts using the ScanDevice.cfg getters
            timer.reloadTimeout();
        });
    }
    startBroadcasting(reason) {
        toast({ msg: 'ScanDevice Started', timeout: 1300 });
        this.teleportToStart();
        this.reloadTimeouts();
        super.startBroadcasting(reason);
    }
    stopBroadcasting(reason) {
        super.stopBroadcasting(reason);
        toast({ msg: 'ScanDevice Stopped', timeout: 1300 });
    }
}
AScanDevice.MIN_DISTANCE_FOR_NEXT_WAYPOINT = 2;
AScanDevice.MAX_DISTANCE_FOR_DETECTION = 10;

import { AError } from "../classes/AError.js";
import { AEngine } from "../core/AEngine.js";
import { APreferenceService, APrefs } from "./APreferenceService.js";
const prefService = AEngine.get(APreferenceService);
export class AComRouteService {
    get routes() { return Object.keys(this.routeMap); }
    constructor() {
        this.routeMap = {};
        this.handleCommandMap = {
            'GetRoutes': (Data) => {
                requestService.send("RouteCommandResponse", {
                    Command: "GetRoutes",
                    CommandId: "",
                    State: "Ok",
                    StateText: "Ok",
                    Routes: "",
                    RoutesNew: [],
                    Time: new Date().toJSON()
                });
            },
            'NewRoute': (Data) => {
                this.routeMap[Data.Name] = Data;
                requestService.send("RouteCommandResponse", {
                    Command: "NewRoute",
                    CommandId: Data.CommandId,
                    State: "Ok",
                    StateText: "Ok",
                    Time: new Date().toJSON()
                });
            },
            'DeleteRoute': (Data) => {
                // Example: {
                //   "Command": "DeleteRoute",
                //   "RouteId": "133731458165010000",
                //   "RouteName": "SMALL_ZONE",
                //   "RouteFromTime": "2024-10-15T10:49:00.000Z",
                //   "RouteToTime": "2024-10-15T10:54:00.000Z"
                // }
                delete this.routeMap[Data.Name];
                AEngine.log(`Not Implemented Yet! Event: RouteCommandRequest->DeleteRoute`);
                Alerts.notImplementedYet();
            }
        };
        this.routeMap = prefService.load(APrefs.ROUTES_ON_CAR, {});
    }
    async autoInit() {
        Events.hardwire(`RouteCommandRequest`, async (Data) => {
            try {
                // console.log(`RouteCommandRequest Command=`, Data.Command)
                if (!this.handleCommandMap.hasOwnProperty(Data.Command)) {
                    return AEngine.warn('UNHANDLED RouteCommandRequest!', Data);
                }
                await Promise.resolve().then(() => this.handleCommandMap[Data.Command](Data));
                prefService.save(APrefs.ROUTES_ON_CAR, this.routeMap);
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                AEngine.log(`Calling %cRouteCommandRequest%p->%r${Data.Command}`, Data);
                Events.tryInvoke(`RouteCommandRequest->${Data.Command}`, Data);
            }
        });
    }
    hasRoutes() {
        return this.routes.length > 0;
    }
    async waitForRoutes() {
        if (!this.hasRoutes()) {
            const Data = await Loading.waitForPromises(requestService.send("PlanRoute_GetRoutesScanAuto_Request", null, { waitForEvent: 'PlanRoute_GetRoutesScanAuto_Response' }));
            for (let DataKey in Data) {
                this.routeMap[Data[DataKey].Name] = Data[DataKey];
            }
        }
        // await this.waitForRoutesPromise
        return this.routes;
    }
    getRoute(RouteName) {
        return this.routeMap[RouteName];
    }
    notifyRouteStarted(RouteName, RouteSessionId) {
        const route = this.getRoute(RouteName);
        const { CommandId, RouteId } = route;
        requestService.send("PlanRouteRouteStarted", {
            CommandId,
            RouteId,
            RouteSessionId: RouteSessionId,
            TimeStamp: new Date().toJSON()
        });
    }
    notifyRouteStopped(RouteName) {
        const route = this.getRoute(RouteName);
        const { CommandId, RouteId } = route;
        requestService.send("PlanRouteRouteStarted", {
            CommandId,
            RouteId: 0,
            RouteSessionId: 0,
            TimeStamp: new Date().toJSON()
        });
    }
}

import { genMarker } from "../../utils/scanauto-geo-tools.js";
export class AFakeGpsDeviceBase {
    constructor(options) {
        this.broadcasting = false;
        this.state = 'none';
        const { startPosition, map } = Object.assign({ type: 'ScanAuto' }, options || {});
        this.startPosition = startPosition;
        this.marker = genMarker({
            type: 'ScanAuto',
            position: startPosition,
            map: map
        });
        this.timers = [];
        this.timerMap = {};
        this.stopWhenRouteFinished = options.stopWhenRouteFinished ?? false;
    }
    config(key, value) {
        if (key === undefined) {
            return this.cfg;
        }
        if (value === undefined) {
            return this.cfg[key];
        }
        this.cfg[key] = value;
    }
    getMap() {
        return this.marker.getMap();
    }
    hasTimer(className) {
        return this.timerMap.hasOwnProperty(className);
    }
    addTimer(timer) {
        const className = timer.constructor.name;
        if (this.hasTimer(className)) {
            throw new Error(`Timer ${className} already exists on this ScanDevice!`);
        }
        this.timers.push(timer);
        this.timerMap[className] = timer;
        // let self: AFakeGpsDeviceBase = this;
        return this;
    }
    getTimer(type) {
        const className = type.name;
        if (!this.hasTimer(className)) {
            throw new Error(`Timer ${className} doesn't exist on this ScanDevice!`);
        }
        return this.timerMap[className];
    }
    delTimer(timer) {
        throw new Error(`Not Implemented Yet!`);
    }
    get speed() {
        return undefined;
    }
    get speedX() {
        return -1 + Math.random() * 2;
    }
    get speedY() {
        return -1 + Math.random() * 2;
    }
    set position(value) {
        this.marker.setPosition(value);
    }
    get position() {
        const position = this.marker.getPosition();
        return (position === null || position === undefined)
            ? this.startPosition : position;
    }
    get pos() {
        return this.position.toJSON();
    }
    isRunning() {
        return this.broadcasting;
    }
    startBroadcasting(reason) {
        this.state = reason;
        this.broadcasting = true;
        for (let timer of this.timers) {
            timer.start();
        }
    }
    stopBroadcasting(reason) {
        this.state = reason;
        this.broadcasting = false;
        for (let timer of this.timers) {
            timer.stop();
        }
    }
}

import { AEngine } from "../core/AEngine.js";
import { AGeoUtils } from "../core/maps/AGeoUtils.js";
import { sendFakeDetection, WAYSEGMENT_GREY, WAYSEGMENT_HIGHLIGHT } from "../utils/scanauto-tools.js";
import { AScanDevice } from "../classes/scandevices/AScanDevice.js";
import { extractFormData, genForm, injectFormValues } from "../utils/forms.js";
import { addTabListenersFind } from "../utils/tabs.js";
import { ANavigationTimer } from "../timers/ANavigationTimer.js";
import { AGpsStateEmitterTimer } from "../timers/AGpsStateEmitterTimer.js";
import { ADetectionEmitterTimer } from "../timers/ADetectionEmitterTimer.js";
import { ACamStateEmitterTimer } from "../timers/ACamStateEmitterTimer.js";
import { ACoreMapService, createMap } from "../core/maps/ACoreMapService.js";
import { MAP_OPTIONS } from "../core/maps/AMapStructs.js";
import { AGpsBufferTimer } from "../timers/AGpsBufferTimer.js";
import { AError } from "../classes/AError.js";
import { AComRouteService } from "../services/AComRouteService.js";
import { escapeHtml } from "../utils/json.js";
import { ARouteScanDevice } from "../classes/scandevices/ARouteScanDevice.js";
import { toast } from "../utils/toasts.js";
import { convertGeoInstancesToMap } from "../utils/maps.js";
import { shine } from "../utils/shine.js";
export class APage {
    constructor() {
        const defaultCoords = geoService.defaultCoordinates;
        this.startPos = defaultCoords.center.toJSON();
        this.startBounds = defaultCoords.bounds.toJSON();
        this.startPosition = new google.maps.LatLng(this.startPos);
        this.map = createMap('#map', {
            center: this.startPosition,
            zoom: 16
        });
        // this.segments = []
        this.waySegments = [];
        this.waySegmentsMap = {};
        this.parkingSpaces = [];
        this.splitParkingSpaces = [];
        this.splitParkingSpacesDataMap = {};
        this.waySegmentToSideSplitParkingSpaceMap = {};
        this.hasParkingSpaces = false;
    }
    async init() {
        addTabListenersFind($('#AjaxContent'));
        this.handleInterfaceEvents();
        let segToWaySegMap = {};
        await Loading.waitForPromises([
            AEngine.get(ACoreMapService).prepareMapItems(MAP_OPTIONS.Area | MAP_OPTIONS.Zone | MAP_OPTIONS.ParkingSpace | MAP_OPTIONS.Segment),
            geoService.loadSegmentToWaySegmentMap().then((geoHashmap) => {
                segToWaySegMap = geoHashmap;
            }),
            geoService.load(this.map, MAP_OPTIONS.ParkingSpace, { parseToMap: true, addClickListener: true }).then((geoInstances) => {
                this.parkingSpaces = geoInstances;
            }),
            geoService.load(this.map, MAP_OPTIONS.SplitParkingSpace, { parseToMap: false, addClickListener: true }).then((geoInstances) => {
                this.splitParkingSpaces = geoInstances;
                this.splitParkingSpaces.map(parkingSpace => {
                    google.maps.event.addListener(parkingSpace, "click", (ref) => {
                        console.log({ parkingSpace, ref });
                    });
                });
            }),
            geoService.load(this.map, MAP_OPTIONS.WaySegment, { parseToMap: false, addClickListener: this.onGeoWaySegmentClick }).then((geoInstances) => {
                this.waySegments = geoInstances;
                this.waySegmentsMap = convertGeoInstancesToMap(this.waySegments, (waySegment) => waySegment.data.GeoId);
            }),
        ]);
        this.waySegmentToSideSplitParkingSpaceMap = {};
        this.splitParkingSpaces.map(v => {
            const splitParkingSpaceId = v.data.GeoId;
            const { SegmentId } = v.data.Attributes;
            if (SegmentId != null) {
                const { WaySegmentId, WaySegmentSide } = segToWaySegMap[SegmentId];
                if (!this.waySegmentToSideSplitParkingSpaceMap.hasOwnProperty(WaySegmentId)) {
                    this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId] = {};
                }
                if (!this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId].hasOwnProperty(WaySegmentSide)) {
                    this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId][WaySegmentSide] = [];
                }
                this.waySegmentToSideSplitParkingSpaceMap[WaySegmentId][WaySegmentSide].push(splitParkingSpaceId);
            }
        });
        this.hasParkingSpaces = Object.keys(this.waySegmentToSideSplitParkingSpaceMap).length > 0;
        // TODO: Use initial GeoMap Instead of creating a new map
        this.splitParkingSpacesDataMap = convertGeoInstancesToMap(this.splitParkingSpaces.filter((parkingSpace) => parkingSpace.data.Attributes.SegmentId != null), (parkingSpace) => parkingSpace.data.GeoId, (parkingSpace) => parkingSpace);
        const scanDevice = new ARouteScanDevice({
            startPosition: this.startPosition,
            stopWhenRouteFinished: true,
            map: this.map
        });
        scanDevice.onStart(async () => {
            $('#scanToggle #start').toggleClass('hidden', true);
            $('#scanToggle #stop').toggleClass('hidden', false);
        });
        scanDevice.onStop(async (reason) => {
            $('#scanToggle #start').toggleClass('hidden', false);
            $('#scanToggle #stop').toggleClass('hidden', true);
            if (reason === 'finished') {
                $('#input-routes').removeAttr('disabled');
                $('#btn-select-route').removeAttr('disabled');
            }
        });
        this.scanDevice = scanDevice
            // .addTimer(new ARouteUITimer({ device: scanDevice, getTimeout: () => 1000 }))
            .addTimer(new ANavigationTimer({
            getTimeout: () => scanDevice.config('interpolationSpeed'), device: scanDevice
        }))
            .addTimer(new AGpsStateEmitterTimer({
            getTimeout: () => scanDevice.config('gpsStateInterval'), device: scanDevice
        }))
            .addTimer(new AGpsBufferTimer({
            getTimeout: () => scanDevice.config('gpsBufferInterval'), device: scanDevice
        }))
            .addTimer(new ACamStateEmitterTimer({
            getTimeout: () => scanDevice.config('camStateInterval'), device: scanDevice
        }))
            .addTimer(new ADetectionEmitterTimer({
            getTimeout: () => scanDevice.config('detectInterval'), detectCallback: () => this.tryScanDetection()
        }));
        const $tbody = $('#routeTable tbody');
        this.scanDevice.listenForNextWaysegment(() => {
            $tbody.find('tr[uid]').toArray().map(tr => $(tr)).map(($tr, i) => {
                $tr.toggleClass('active', this.scanDevice.currentRouteItem.selectionIndex === i);
            });
        });
        this.scanDevice.listenForNextStep(() => {
            $tbody.html(this.scanDevice.waysegments.map((waysegment) => {
                return waysegment ? ( /*html*/`
          <tr uid="${waysegment.data.GeoId}">
            <td>${waysegment.data.GeoId} ${waysegment.data.Name}</td>
          </tr>
        `) : '';
            }).join(''));
            $tbody.find('tr[uid]').toArray().map(tr => $(tr)).map(($tr, i) => {
                $tr.toggleClass('active', this.scanDevice.currentRouteItem.selectionIndex === i);
                $tr.on('click', (e) => {
                    this.focusOnRoad(Number($tr.attr('uid')));
                });
            });
        });
        $('.loading-page-init').fadeOut();
    }
    tryScanDetection() {
        try {
            if (!this.selectNewParkingSpace()) {
                return;
            }
            const DetectionGps = this.scanDevice.detectionIndicator.getPosition()?.toJSON() || this.scanDevice.pos;
            const GpsVehicleBounds = AGeoUtils.rotateDetectionBounds(DetectionGps, this.scanDevice.detectionIndicator);
            const data = {
                ScanDeviceGps: this.scanDevice.pos,
                DetectionGps: DetectionGps,
                GpsVehicleBounds: GpsVehicleBounds
            };
            AGeoUtils.parsePolgonUsingBounds(GpsVehicleBounds, { parseToMap: true });
            // Events.once('ImageRequest', (Data) => HandleImageRequest(Data))
            globalThis.sendImagesAsPng = this.scanDevice.config('sendImagesAsPng') ?? globalThis.sendImagesAsPng;
            sendFakeDetection(data, this.scanDevice.config());
            injectFormValues(this.$form, {
                detectionIndex: this.scanDevice.config('detectionIndex')
            }, false);
        }
        catch (err) {
            AError.handle(err);
        }
    }
    selectNewParkingSpace() {
        const { currentRouteItem } = this.scanDevice;
        if (!currentRouteItem.waySegment) {
            console.log(`Current route item doesn't have a waySegment!`);
            return false;
        }
        // Key 1 = WaySegmentId, key 2 = WaySegmentSide
        // Value = list of SplitParkingSpaceIds
        const waySegmentId = currentRouteItem.waySegment.data.GeoId;
        if (this.hasParkingSpaces) {
            if (!this.waySegmentToSideSplitParkingSpaceMap.hasOwnProperty(waySegmentId)) {
                console.log(`Current route item doesn't have any parkingspaces!`);
                return false;
            }
            const sides = this.waySegmentToSideSplitParkingSpaceMap[waySegmentId];
            const allSplitParkingSpaceIds = Object.values(sides).flat();
            const id = allSplitParkingSpaceIds[Math.floor(Math.random() * allSplitParkingSpaceIds.length)];
            const ps = this.splitParkingSpacesDataMap[id];
            if (!this.scanDevice.detectionIndicator.hasOwnProperty('data')) {
                this.scanDevice.detectionIndicator.data = {};
            }
            this.scanDevice.detectionIndicator.data.target = ps;
            this.scanDevice.detectionIndicator.setPosition(AGeoUtils.getRandomPointInside(ps));
        }
        else {
            this.scanDevice.detectionIndicator.setPosition(this.scanDevice.pos);
        }
        return true;
    }
    get $form() {
        return $(`[tabview="setup"]`).find('form');
    }
    getFormData($form) {
        const formData = extractFormData($form.is('form') ? $form : $form.find('form'));
        return Object.assign({}, this.scanDevice.getConfigurableOptions(), formData);
    }
    handleInterfaceEvents() {
        const $form = this.$form;
        $form.on('submit', (e) => e.preventDefault());
        const $inputs = $form.find(':input');
        $inputs.on('change keyup', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const formData = this.getFormData($form);
            this.scanDevice.setConfigurableOptions(formData);
        });
        const $sRoutes = $('#input-routes');
        const $btnConfirmRoute = $('#btn-select-route');
        const comRouteService = AEngine.get(AComRouteService);
        comRouteService.waitForRoutes().then((routes) => {
            const noneOption = $sRoutes.html().trim();
            if (routes.length > 0) {
                $sRoutes.html(routes.map(r => /*html*/ `<option value="${escapeHtml(r)}">${escapeHtml(r)}</option>`).join(''));
            }
            Events.on(`RouteCommandRequest->NewRoute`, () => {
                toast({ msg: 'Received Route!' });
                let v = $sRoutes.val();
                $sRoutes.html((comRouteService.hasRoutes()) ? comRouteService.routes.map(r => /*html*/ `<option value="${escapeHtml(r)}">${escapeHtml(r)}</option>`).join('') : noneOption);
                $sRoutes.val(v);
            });
            Events.on(`RouteCommandRequest->DeleteRoute`, () => {
                let v = $sRoutes.val();
                $sRoutes.html((comRouteService.hasRoutes()) ? comRouteService.routes.map(r => /*html*/ `<option value="${escapeHtml(r)}">${escapeHtml(r)}</option>`).join('') : noneOption);
                $sRoutes.val(v);
            });
        });
        Events.once(`ACI_TABS_CHANGED->sideview->route`, () => shine({ target: $('#route-meter') }));
        Events.on(`SCANDEVICE_POS_INDEX_CHANGED`, () => {
            const perc = this.scanDevice.routeProgress;
            $('#route-meter .bar-time-left').text(`${perc.toFixed(2)} %`);
            $('#route-meter .bar-progress').css('right', `${Math.floor(100.0 - perc)}%`);
        });
        Events.on(`SCANDEVICE_INTERPOLATE`, () => {
            const perc = this.scanDevice.routeProgress;
            $('#route-meter .bar-time-left').text(`${perc.toFixed(2)} %`);
            $('#route-meter .bar-progress').css('right', `${Math.floor(100.0 - perc)}%`);
        });
        $sRoutes.on('change', () => {
            this.scanDevice.reset();
            let selectedRoute = $sRoutes.val();
            if (selectedRoute?.length > 0) {
                $btnConfirmRoute.removeAttr('disabled');
                this.scanDevice.loadRoute(selectedRoute, { waySegmentsMap: this.waySegmentsMap });
            }
            else {
                $btnConfirmRoute.attr('disabled', 'disabled');
            }
        });
        $btnConfirmRoute.on('click', () => {
            let selectedRoute = $sRoutes.val();
            if (selectedRoute?.length > 0) {
                $('#scanToggle #start').removeAttr('disabled');
                $sRoutes.attr('disabled', 'disabled');
                $btnConfirmRoute.attr('disabled', 'disabled');
                // this.scanDevice.unloadRoute()
                // this.scanDevice.loadRoute(selectedRoute, {waySegmentsMap: this.waySegmentsMap})
            }
        });
        $('#scanToggle #start').on('click', () => {
            this.scanDevice.startBroadcasting('running');
        });
        $('#scanToggle #stop').on('click', () => {
            this.scanDevice.stopBroadcasting('paused');
        });
    }
    onGeoWaySegmentClick(event) {
        // @ts-ignore
        const wayseg = this;
        const addedToRoute = PageScript.scanDevice.toggleWaysegment(wayseg);
        wayseg.setOptions({ strokeColor: (addedToRoute) ? WAYSEGMENT_HIGHLIGHT : WAYSEGMENT_GREY });
    }
    focusOnRoad(mapKey) {
        const waySegment = this.waySegmentsMap[mapKey];
        const path = waySegment.getPath();
        const latLng = path.getAt(Math.floor(path.getLength() / 2));
        console.log(latLng.toJSON());
        this.map.setCenter(latLng);
    }
}
export function css() {
    return ( /*html*/`
    <style>
      @font-face {
        font-family: Kenteken;
        src: url('/font/Kenteken.ttf') format('truetype');
        /*
      src: url('/font/Kenteken.eot'); /* IE9 Compat Modes */
        src: url('/font/Kenteken.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */
          url('/font/Kenteken.woff') format('woff'),
          /* Pretty Modern Browsers */
          url('/font/Kenteken.ttf') format('truetype'),
          /* Safari, Android, iOS */
          url('/font/Kenteken.svg#svgFontName') format('svg');
        /* Legacy iOS */
        font-weight: normal;
      }
      .aci-tabs {
        background: #f1f0f0;
      }
      [tabview] {
        height: 100%;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        overflow-y: auto;
      }
      .aci-tabs {
        /*height: 56px; */
      }
      .views {
        height: calc(100% - 56px)
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="wrapper lg columns col-gapless">
      <div class="column col-4" style="background: #f1f0f0; height: 100%">
        <div class="hidden preload" style="font: 40px opensans"></div>
        <div class="hidden preload" style="font: 30px Kenteken"></div>
      
        <div class="aci-tabs tabs-flex tabs-sticky tabs-fixed-md tabs-xl v-zero sharp-border" tabgroup="sideview">
          <button style="flex: 0 1 50%;" class="aci-tab active" tab="setup">
            <i class="fa-solid fa-car-wrench"></i>
            <span>Setup</span>
          </button>
          <button style="flex: 0 1 50%;" class="aci-tab" tab="route">
            <i class="fa-solid fa-route"></i>
            <span>Route</span>
          </button>
        </div>
        <div class="views" style="position: relative">
          <div class="loading-page-init">
            <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem; height: 100%;"></div>
          </div>
          <div class="column col-12" tabgroup="sideview" tabview="setup">
            <div style="height: calc(100% - 36px - 36px - 25px); overflow: auto;">
              ${genForm({
        detectionPrefix: {
            type: 'text',
            title: 'detectionPrefix',
            width: 'col-6'
        },
        detectionIndex: {
            type: 'number',
            title: 'detectionIndex',
            value: 1,
            width: 'col-6'
        },
        inheritStreetMaxSpeed: {
            type: "checkbox",
            title: 'inheritStreetMaxSpeed',
        },
        targetSpeed: {
            type: 'number',
            title: 'targetSpeed',
        },
        detectInterval: {
            type: 'number',
            title: 'detectInterval (milliseconds)',
        },
        camStateInterval: {
            type: 'number',
            title: 'camStateInterval (milliseconds)',
        },
        gpsStateInterval: {
            type: 'number',
            title: 'gpsStateInterval (milliseconds)',
        },
        gpsBufferInterval: {
            type: 'number',
            title: 'gpsBufferInterval (milliseconds)',
        },
        centerVehicle: {
            type: 'checkbox',
            title: 'Keep Vehicle Centered',
            width: 'col-6'
        },
        sendImagesAsPng: {
            type: 'checkbox',
            title: 'Send Images as PNG',
            width: 'col-6',
            value: globalThis.sendImagesAsPng ?? true
        }
    }, {
        valueMapper(propertyName) {
            const template = AScanDevice.defaultConfigurableOptions();
            if (!template.hasOwnProperty(propertyName)) {
                throw new Error(`Couldn't generate form because property "${propertyName} couldn't be found!"`);
            }
            return template[propertyName];
        },
        cls: 'columns col-gapless mb-1'
    })}
            </div>
            
            <div class="divider"></div>
            <div class="input-group mb-1">
              <select id="input-routes" class="form-select form-input full-width col-6">
                <option value="">None</option>
              </select>
              <button id="btn-select-route" class="btn btn-white btn-shadow-sm full-width input-group-btn col-6">Select Route</button>
            </div>
            
            <div id="scanToggle" class="input-group">
              <button id="start" class="btn btn-primary full-width input-group-btn col-12" disabled="disabled">Start Scanning</button>
              <button id="stop" class="btn btn-error full-width input-group-btn col-12 hidden">Stop Scanning</button>
            </div>
          </div>
          <div class="column col-12 c-scroll" tabgroup="sideview" tabview="route">
            <div id="meter-container" class="mb-1" style="height: 60px">
              <div id="route-meter" class="custom-time-bar" value="100.0019363360761">
                <div class="bar-time-left">0.00 %</div>
                <div class="bar-progress" style="right: 100%;"></div>
              </div>
            </div>
            <table id="routeTable" class="styled-table grid-like full-width">
              <thead>
                <tr class="head noselect">
                  <th>Route</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="column col-8" style="height: 100%; background: #f8f9fa">
        <div id="map" class="aci-map"></div>
      </div>
    </div>
  `);
}
